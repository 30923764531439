#about{
    /* background: url(./service-bg.jpg); */
    background: #0E0E0E;
}

#about .grid-cols-2 > div:first-child {
    background-color: #0E0E0E;
    background-size: cover;
    background-position: center;
    padding: 2rem;
  }
  
  #about .grid-cols-2 > div:last-child {
    /* background: url(./service-bg.jpg); */
    background-color: #fff; 
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 2rem;
  }

  .left-column h2 {
    color: goldenrod; 
  }
  
  .text-gold {
    color: goldenrod; 
  }
  .right-column p {
    line-height: 1.5;
  }
  
  .right-column h2 {
    color: #1a202c; /* Heading color */
  }