#service {
    margin-top: 70px;
    padding: 70px 0;
    background: url('./service-bg.jpg');
  }
  
  .card.custom {
    transition: transform 0.3s, border-color 0.3s; 
    border-bottom: 5px solid transparent; 
}
  .card:hover {
    transform: scale(1.05);
    border-bottom-color: #ffd700; 
  }

  .golden-icon {
    color: goldenrod;
  }