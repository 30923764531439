section{
    /* padding-top: 60px; */
    overflow: hidden;
}
#Hero{
    width: 100%;
    height: 75vh;
    background: #0E0E0E;
    border-bottom: 2px solid #fcebe3;
    margin: 60px 0 -60px 0;
}

@media (max-height: 650px) {
    #Hero {
      height: 120vh; 
    }
  }
  @media (max-width: 990px) {
    #Hero {
      height: 120vh; 
    }
  }
#Hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 900;
    line-height: 56px;
    -webkit-background-clip: text;
    background-clip: text;
    white-space: nowrap; 
    overflow: hidden; 
    border-right: 2px solid #0E0E0E;
    color: gold;
    background-size: 300%;
    background-position: -100%;
}
#Hero img { 
    max-width: 80vw; 
    margin: 0 auto; 
  }

@keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .animate-pulse {
    animation: pulse 4s infinite;
  }
